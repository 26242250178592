/* eslint-disable @typescript-eslint/ban-types */
import React, { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import moment from 'moment';
import * as momentTZ from 'moment-timezone';

import 'moment/locale/nl';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/ja';
import 'moment/locale/ko';
import 'moment/locale/pt';
import 'moment/locale/zh-cn';
import antLocaleDE from 'antd/lib/locale/de_DE';
import antLocaleNL from 'antd/lib/locale/nl_NL';
import antLocaleFR from 'antd/lib/locale/fr_FR';
import antLocaleJP from 'antd/lib/locale/ja_JP';
import antLocaleKO from 'antd/lib/locale/ko_KR';
import antLocalePT from 'antd/lib/locale/pt_PT';
import antLocaleZH from 'antd/lib/locale/zh_CN';
import antLocaleEN from 'antd/lib/locale/en_US';
import { ConfigProvider } from 'antd';

import { useAuth } from './use-auth';

interface LocaleContextInterface {
  /** Whether the user locale has been synced to the Next.js router */
  localeChecked: boolean;
}

/**
 * Simple React Context for locale data.
 */
const LocaleContext = React.createContext<LocaleContextInterface>({
  localeChecked: false,
});

/**
 * Hook to easily use the LocaleContext in child components.
 */
export function useLocale() {
  return useContext(LocaleContext);
}

const getLocale = (code: string) => {
  switch (code) {
    case 'de':
      return antLocaleDE;
    case 'nl':
      return antLocaleNL;
    case 'fr':
      return antLocaleFR;
    case 'ja':
      return antLocaleJP;
    case 'ko':
      return antLocaleKO;
    case 'pt':
      return antLocalePT;
    case 'zh':
      return antLocaleZH;
    default:
      return antLocaleEN;
  }
};

/**
 * Provider that is responsible for:
 * 1. Setting the Moment.js locale based on `user.language`.
 * 2. Syncing Next.js router locale with the `user.language`.
 */
export function ProvideLocale({ children }: { children: React.ReactNode }) {
  const router = useRouter();
  const { i18n } = useTranslation(); // from next-i18next
  const { user } = useAuth(); // grab user (and other relevant data) from the Auth provider
  const [localeChecked, setLocaleChecked] = useState<boolean>(false);

  /**
   * Whenever the user object changes, update Moment/Moment-TZ locale.
   */
  useEffect(() => {
    const newLocale = user?.language ?? 'en';
    moment.locale(newLocale);
    momentTZ.locale(newLocale);
  }, [user]);

  /**
   * Sync Next.js router locale with user language if:
   *  - we haven't already checked the locale,
   *  - the user is logged in,
   *  - the user object is available,
   *  - and the user’s language differs from the router locale.
   */
  useEffect(() => {
    if (!router.isReady) return;

    if (!localeChecked && user) {
      // Make sure the user language is actually in your Next i18n config
      if (
        user.language &&
        router.locale !== user.language &&
        // @ts-ignore
        (i18n.options?.locales || []).includes(user.language)
      ) {
        console.log(
          `Locale mismatch detected: router has "${router.locale}", user has "${user.language}". Redirecting...`
        );
        const { pathname, query, asPath } = router;
        router.push({ pathname, query }, asPath, { locale: user.language });
      } else {
        // check if token  or api_key in URL and remove it
        const { pathname, query, asPath } = router;

        if (
          query.token ||
          query.api_key ||
          asPath.includes('token') ||
          asPath.includes('api_key')
        ) {
          delete query.token;
          delete query.api_key;

          console.log('Removing token or api_key from URL');
          router.push({ pathname, query }, undefined, {
            locale: user.language || 'en',
          });
        }
      }

      // Mark locale as checked to prevent repeated pushes
      setLocaleChecked(true);
    }
  }, [localeChecked, user, router, i18n.options]);

  return (
    <LocaleContext.Provider value={{ localeChecked }}>
      <ConfigProvider locale={getLocale(router.locale as string)}>
        {children}
      </ConfigProvider>
    </LocaleContext.Provider>
  );
}
