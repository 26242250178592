import React, { useCallback, useState } from 'react';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { AutoComplete, Button, Modal, Typography } from 'antd';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

import type { PaginatedProfileList, Profile } from 'src/api-sdk';
import { ProfilesApi } from 'src/api-sdk';
import { useAuth } from 'src/util/use-auth';
import { useAPI } from 'src/util/use-api';
import { Loader } from 'src/components/Loader';
import { QUERIES } from 'src/util/globals';

export const UserMenu = () => {
  const [switchModalVisible, setSwitchModalVisible] = useState(false);
  const [profile, setProfile] = useState<Profile | null>(null);
  const { t } = useTranslation('menu');
  const { user } = useAuth();
  const { api, configuration } = useAPI();
  const [searchQuery, setSearchQuery] = React.useState<string | null>(null);
  const queryClient = useQueryClient();

  const getProfiles = useCallback(() => {
    return new Promise<PaginatedProfileList>((resolve, reject) => {
      new ProfilesApi(configuration, undefined, api)
        .profilesList({ query: searchQuery || undefined })
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => reject(error));
    });
  }, [api, configuration, searchQuery]);

  const {
    data: profiles,
    isFetching,
    refetch,
  } = useQuery<PaginatedProfileList>([QUERIES.PROFILES], getProfiles);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRefetch = useCallback(
    AwesomeDebouncePromise(() => {
      refetch().then(() => {});
    }, 200),
    [refetch]
  );

  const handleClose = () => setSwitchModalVisible(false);
  const handleSwitchProfile = () => {
    new ProfilesApi(configuration, undefined, api)
      .profilesUserDetailsPartialUpdate({
        patchedUserUpdateRequest: { active_profile: profile?.external_id },
      })
      .then(() => {
        queryClient.invalidateQueries().then(handleClose);
      });
  };

  // @ts-ignore
  // @ts-ignore
  return (
    <>
      <ul className="gx-user-popover">
        <li>
          <Link href="/account" legacyBehavior>
            {t('My Account')}
          </Link>
        </li>
        <li>
          <a
            href=""
            onClick={(event) => {
              setSwitchModalVisible(true);
              event.preventDefault();
            }}
          >
            {t('Switch Profile')}
          </a>
        </li>
        <li>
          <Link href="/logout" legacyBehavior>
            {t('Logout')}
          </Link>
        </li>
      </ul>
      <Modal
        title={t('Switch Profile')}
        visible={switchModalVisible}
        onCancel={handleClose}
        destroyOnClose
        footer={
          <>
            <Button onClick={handleClose}>{t('Cancel')}</Button>
            <Button type="primary" onClick={handleSwitchProfile}>
              {t('Switch Profile')}
            </Button>
          </>
        }
      >
        <>
          <Typography.Title
            level={5}
            style={{ textAlign: 'center', marginBottom: 15 }}
          >
            {t('Current Profile')}: {user?.active_profile?.name}
          </Typography.Title>
          <Loader loading={isFetching}>
            <AutoComplete
              value={searchQuery}
              options={(profiles?.results || []).map((profile: Profile) => ({
                value: profile.name,
                profile: profile,
                label: profile.name,
              }))}
              style={{ width: '100%' }}
              // @ts-ignore
              onSelect={(label, option) => {
                setProfile(option.profile);
              }}
              // @ts-ignore
              onSearch={debouncedRefetch}
              onChange={setSearchQuery}
              placeholder={t('Profile Name')}
            />
          </Loader>
        </>
      </Modal>
    </>
  );
};
