import { useMutation } from '@tanstack/react-query';

import type { PortfolioUserUpdate, PermissionEnum } from 'src/api-sdk';
import type { MutationHookOptions } from 'src/types/global';
import { useAPI } from 'src/util/use-api';

type UpdateUserPermissionForPortfolioParams = {
  portfolioId: string;
  userId: string;
  permission: PermissionEnum;
};

export const useUpdateUserPermissionForPortfolio = (
  options: MutationHookOptions<
    PortfolioUserUpdate | undefined,
    Error,
    UpdateUserPermissionForPortfolioParams
  > = {}
) => {
  const { API } = useAPI();

  return useMutation(
    async ({
      portfolioId,
      permission,
      userId,
    }: UpdateUserPermissionForPortfolioParams) => {
      return (
        await API?.portfoliosAPI.portfoliosUsersPartialUpdate({
          portfolioId,
          portfolioUserId: userId,
          patchedPortfolioUserUpdateRequest: {
            permission,
          },
        })
      )?.data;
    },
    { ...options }
  );
};
