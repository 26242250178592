import { useInfiniteQuery } from '@tanstack/react-query';

import type { PaginatedBinderProfileUserGroupList } from 'src/api-sdk';
import type { InfiniteQueryHookOptions } from 'src/types/global';
import { useAPI } from 'src/util/use-api';
import { QUERIES } from 'src/util/globals';

type UseBinderGroupsParams = {
  searchQuery?: string;
  binderId?: string;
};

type Data = PaginatedBinderProfileUserGroupList | undefined;

export const useBinderGroups = (
  { binderId, searchQuery }: UseBinderGroupsParams,
  options: InfiniteQueryHookOptions<Data, Error, Data> = {}
) => {
  const { API } = useAPI();

  return useInfiniteQuery(
    [QUERIES.BINDER_GROUPS, binderId, searchQuery],
    async ({ pageParam }) => {
      return (
        await API?.bindersAPI.bindersProfileUserGroupsList({
          sorting: 'created_at',
          sortingOrder: 'descending',
          binderId: binderId!,
          query: searchQuery,
          nextKey: pageParam,
        })
      )?.data;
    },
    {
      ...options,
      getNextPageParam: (lastPage) => lastPage?.next_key ?? undefined,
      keepPreviousData: true,
    }
  );
};
