import { useMutation } from '@tanstack/react-query';

import type { MutationHookOptions } from 'src/types/global';
import { useAPI } from 'src/util/use-api';

type RemoveGroupFromPortfolioParams = {
  portfolioId: string;
  groupId: string;
};

export const useRemoveGroupFromPortfolio = (
  options: MutationHookOptions<void, Error, RemoveGroupFromPortfolioParams>
) => {
  const { API } = useAPI();

  return useMutation(
    async ({ portfolioId, groupId }: RemoveGroupFromPortfolioParams) => {
      return (
        await API?.portfoliosAPI.portfoliosProfileUserGroupsDestroy({
          portfolioId,
          profileUserGroupId: groupId,
        })
      )?.data;
    },
    { ...options }
  );
};
