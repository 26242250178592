import { useInfiniteQuery } from '@tanstack/react-query';

import { useAPI } from 'src/util/use-api';
import { QUERIES } from 'src/util/globals';

type UseUsersParams = {
  enabled?: boolean;
  searchQuery?: string;
};

export const useUsers = ({ enabled = true, searchQuery }: UseUsersParams) => {
  const { API } = useAPI();

  return useInfiniteQuery(
    [QUERIES.USERS, searchQuery],
    async ({ pageParam }) => {
      return (
        await API?.profilesAPI.profilesUsersList({
          nextKey: pageParam ?? undefined,
          query: searchQuery,
        })
      )?.data;
    },
    {
      getNextPageParam: (lastPage) => lastPage?.next_key ?? undefined,
      keepPreviousData: true,
      enabled,
    }
  );
};
