import React, { useContext, useEffect, useState } from 'react';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { notification } from 'antd';

import type { WebsocketSession } from 'src/api-sdk';
import { AlertsApi } from 'src/api-sdk';

import { useAPI } from './use-api';
import { useAuth } from './use-auth';
import { QUERIES } from './globals';

interface WebsocketContextInterface {
  connected: boolean;
  session: WebsocketSession | undefined;
  websocket: WebSocket | undefined;
  sendToWebsocket: (data: any) => any;
  registerListener: (listener: WebsocketListener) => any;
  removeListener: (listener: WebsocketListener) => any;
}

export interface WebsocketListener {
  key: string;
  message_type: WebsocketMessageType;
  callback: (message: WebsocketMessage) => any;
}

export enum WebsocketMessageType {
  REGISTRATION_UPDATE = 'REGISTRATION_UPDATE',
  REGISTRATION_COMPANY_UPDATE = 'REGISTRATION_COMPANY_UPDATE',
  REGISTRATION_ARTICLE_UPDATE = 'REGISTRATION_ARTICLE_UPDATE',
  NEW_ARTICLE_PUBLISHED = 'NEW_ARTICLE_PUBLISHED',
  BINDER_SUMMARY_UPDATE = 'BINDER_SUMMARY_UPDATE',
  ARTICLE_SUMMARY_UPDATE = 'ARTICLE_SUMMARY_UPDATE',
  ARTICLE_EXPORT_AVAILABLE = 'ARTICLE_EXPORT_AVAILABLE',
  EXPORT_AVAILABLE = 'EXPORT_AVAILABLE',
  BATCH_REGISTRATION_STATUS_UPDATE = 'BATCH_REGISTRATION_STATUS_UPDATE',
}

export interface WebsocketMessage {
  message_type: WebsocketMessageType;
  data: any;
}

const websocketContext = React.createContext<WebsocketContextInterface>(
  {} as ReturnType<typeof useProvideWebsocket>
);

export function ProvideWebsocket({ children }: any): any {
  const websocket = useProvideWebsocket();
  return (
    <websocketContext.Provider value={websocket}>
      {children}
    </websocketContext.Provider>
  );
}

export const useWebsocket = () => {
  return useContext(websocketContext);
};

// Provider hook that creates auth object and handles state
function useProvideWebsocket(): WebsocketContextInterface {
  const { api, authHeaderSet, configuration } = useAPI();
  const { user } = useAuth();
  const [connected, setConnected] = useState<boolean>(false);
  const [listeners, setListeners] = useState<WebsocketListener[]>([]);
  const [websocket, setWebsocket] = useState<WebSocket | undefined>();

  const baseWebsocketUrl = process.env.NEXT_PUBLIC_API_WEBSOCKETS_URL as string;

  const session = useQuery<WebsocketSession>(
    [QUERIES.WEBSOCKET_SESSION],
    AwesomeDebouncePromise(async () => {
      return await getWebsocketSession();
    }, 300),
    {
      enabled: Boolean(authHeaderSet && user),
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  /**
   * Get new Websocket Session.
   */
  const getWebsocketSession = React.useCallback(() => {
    return new Promise<WebsocketSession>((resolve, reject) => {
      new AlertsApi(configuration, undefined, api)
        .alertsWebsocketsSessionsCreate()
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => reject(error));
    });
  }, [api, configuration]);

  const onWebsocketClose = React.useCallback(
    (e: { code: number; reason: any }) => {
      console.log(`WebSocket is closed: ${e.code} ${e.reason}`);
      // 1000 => forced close, do not reconnect
      if (e.code === 1001) {
        setWebsocket(undefined);
      }
    },
    [setWebsocket]
  );

  const onWebsocketMessage = React.useCallback(
    (e: { data: string }) => {
      const data: { message_type: string } = JSON.parse(e.data);
      const message: WebsocketMessage = {
        // @ts-ignore
        message_type: WebsocketMessageType[data.message_type],
        data: {
          ...data,
          message_type: undefined,
        },
      };

      console.log('Websocket message received', message.data);

      if (
        message.message_type === WebsocketMessageType.EXPORT_AVAILABLE &&
        // @ts-ignore
        message.data?.download_url
      ) {
        notification.success({
          message: 'Export available',
          description: 'Click here to download',
          onClick: () => {
            // @ts-ignore
            window.open(message.data?.download_url);
          },
          duration: 900,
        });
      }

      console.debug(
        `Websocket message received with ${listeners.length} listeners`,
        message
      );

      for (const listener of listeners) {
        if (listener.message_type !== message.message_type) {
          continue;
        }

        try {
          listener.callback(message);
        } catch (e) {
          console.log(`Callback of listener ${listener} failed due to ${e}`);
        }
      }
    },
    [listeners]
  );

  const registerListener = React.useCallback(
    (listener: WebsocketListener) => {
      // console.debug("Registering listener", listener)
      setListeners((prevState: WebsocketListener[]) => {
        prevState = prevState.filter((value) => value.key !== listener.key);
        return [...prevState, listener];
      });
    },
    [setListeners]
  );

  const removeListener = React.useCallback(
    (listener: WebsocketListener) => {
      // console.debug("Removing listener", listener)
      setListeners((prevState: WebsocketListener[]) => {
        prevState = prevState.filter((value) => value.key !== listener.key);
        return [...prevState];
      });
    },
    [setListeners]
  );

  useEffect(() => {
    if (session.data && !websocket) {
      // Check if session is not yet expired.
      if (moment(session.data.expired_at) < moment()) {
        console.log(`Websocket Session expired: ${session.data}`);
        session.refetch().then(() => {
          console.log(`Websocket Session reset.`);
        });
        setWebsocket(undefined);
        return;
      }
      // Create new websocket.
      const websocket = new WebSocket(
        `${baseWebsocketUrl}?access_token=${session.data.access_token}`
      );
      setWebsocket(websocket);
    }

    if (websocket) {
      websocket.onopen = () => {
        setConnected(true);
      };
      websocket.onmessage = onWebsocketMessage;
      websocket.onclose = (e) => {
        if (e.code === 1e3 || e.code === 1001 || e.code === 1005) {
          setTimeout(() => setWebsocket(undefined), 100);
        } else {
          onWebsocketClose(e);
        }
      };
      websocket.onerror = (e) => {
        // @ts-ignore
        if (e && e.code === 'ECONNREFUSED') {
          setTimeout(() => setWebsocket(undefined), 100);
        }
      };
    }
  }, [
    session,
    websocket,
    setWebsocket,
    baseWebsocketUrl,
    onWebsocketClose,
    onWebsocketMessage,
    listeners,
  ]);

  const sendToWebsocket = React.useCallback(
    (data) => {
      if (websocket && connected) {
        console.debug(`Sending update to websocket: ${JSON.stringify(data)}`);
        try {
          websocket.send(JSON.stringify(data));
        } catch (e) {
          console.error('Failed sending update to websocket');
        }
      }
    },
    [websocket, connected]
  );

  return {
    connected: connected,
    session: session.data,
    websocket,
    sendToWebsocket,
    registerListener,
    removeListener,
  };
}
