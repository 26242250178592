import React from 'react';
import type { TableProps } from 'antd/lib/table';
import Table from 'antd/lib/table';
import { useVT } from 'virtualizedtableforantd4';
import { FileSearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'next-i18next';

import { Loader } from './Loader';

const scrollConfig = {
  y: 465,
  x: 0,
};

interface Props<RecordType extends object> extends TableProps<RecordType> {
  debug?: boolean;
  infinity?: boolean;
  scroll?: any;
  lastId?: string;
  dataSource: RecordType[];
  loading: boolean;
  fetchNext: () => void;
  hasNext: boolean;
  emptyText?: string | null;
}

export const SmartTable = <RecordType extends object = any>({
  debug = false,
  infinity = false,
  scroll = scrollConfig,
  dataSource = [],
  lastId = '',
  loading = false,
  fetchNext = () => null,
  hasNext = false,
  emptyText = null,
  onRow, // <-- explicitly extract onRow
  ...props
}: Props<RecordType>) => {
  const { t } = useTranslation('common');
  const [vt] = useVT(
    () => ({
      // @ts-ignore
      onScroll: ({ isEnd }) => {
        if (isEnd && hasNext) {
          fetchNext();
        }
      },
      scroll: { y: scrollConfig.y },
      debug,
    }),
    [lastId, dataSource, fetchNext, hasNext]
  );

  const renderEmpty = React.useCallback(
    () => (
      <div style={{ textAlign: 'center' }}>
        <FileSearchOutlined style={{ fontSize: 20 }} />
        <p>{emptyText || t('No data found')}</p>
      </div>
    ),
    [t, emptyText]
  );

  if (!infinity) {
    return (
      <Loader loading={loading}>
        <Table
          onRow={onRow} // pass onRow here
          locale={{ emptyText: renderEmpty() }}
          dataSource={dataSource}
          pagination={false}
          {...props}
        />
      </Loader>
    );
  }

  return (
    <Loader loading={loading}>
      <Table
        onRow={onRow} // and here
        dataSource={dataSource}
        scroll={scroll}
        components={vt}
        pagination={false}
        locale={{ emptyText: renderEmpty() }}
        {...props}
      />
    </Loader>
  );
};
