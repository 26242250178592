import { useMutation } from '@tanstack/react-query';

import type { MutationHookOptions } from 'src/types/global';
import { useAPI } from 'src/util/use-api';

type RemoveUserFromPortfolioParams = {
  portfolioId: string;
  userId: string;
};

export const useRemoveUserFromPortfolio = (
  options: MutationHookOptions<void, Error, RemoveUserFromPortfolioParams> = {}
) => {
  const { API } = useAPI();

  return useMutation(
    async ({ portfolioId, userId }: RemoveUserFromPortfolioParams) => {
      return (
        await API?.portfoliosAPI.portfoliosUsersDestroy({
          portfolioId,
          portfolioUserId: userId,
        })
      )?.data;
    },
    { ...options }
  );
};
