import { useQueries } from '@tanstack/react-query';

import { useAPI } from 'src/util/use-api';

export default function useSearch(query: string | string[] | undefined) {
  const { API } = useAPI();

  const results = useQueries({
    queries: [
      {
        queryKey: ['companies-unified', query],
        queryFn: () =>
          API?.companiesAPI.companiesUniversalSearchList({
            query: query?.toString() ?? '',
          }),
        enabled: !!query,
        retry: false,
      },
      {
        queryKey: ['portfolios-unified', query],
        queryFn: () =>
          API?.portfoliosAPI.portfoliosList({
            query: query?.toString() ?? '',
          }),
        enabled: !!query,
        retry: false,
      },
      {
        queryKey: ['binders-unified', query],
        queryFn: () =>
          API?.bindersAPI.bindersList({
            query: query?.toString() ?? '',
            sorting: 'created_at',
            sortingOrder: 'ascending',
          }),
        enabled: !!query,
        retry: false,
      },
      {
        queryKey: ['articles-unified', query],
        queryFn: () =>
          API?.newsAPI.newsArticlesList({
            query: query?.toString() ?? '',
          }),
        enabled: !!query,
        retry: false,
      },
    ],
  });
  const isLoading = results.some((result) => result.isLoading);
  const companiesData = results[0].data?.data;
  const portfolioData = results[1].data?.data;
  const binderData = results[2].data?.data;
  const articlesData = results[3].data?.data;
  const isError = results.some((result) => result.isError);

  return {
    isLoading,
    companiesData,
    portfolioData,
    binderData,
    articlesData,
    isError,
  };
}
