import { useMutation } from '@tanstack/react-query';

import type { BinderUser, PermissionEnum } from 'src/api-sdk';
import type { MutationHookOptions } from 'src/types/global';
import { useAPI } from 'src/util/use-api';

type UpdateUserPermissionForBinderParams = {
  binderId: string;
  userId: string;
  permission: PermissionEnum;
};

export const useUpdateUserPermissionForBinder = (
  options: MutationHookOptions<
    BinderUser | undefined,
    Error,
    UpdateUserPermissionForBinderParams
  > = {}
) => {
  const { API } = useAPI();

  return useMutation(
    async ({
      binderId,
      permission,
      userId,
    }: UpdateUserPermissionForBinderParams) => {
      return (
        await API?.bindersAPI.bindersUsersPartialUpdate({
          binderId,
          binderUserId: userId,
          patchedBinderUserRequest: {
            permission,
          },
        })
      )?.data;
    },
    { ...options }
  );
};
