/* eslint-disable @typescript-eslint/ban-types */
import React, { useContext, useState } from 'react';
import { getCookie, getCookies, removeCookies, setCookies } from 'cookies-next';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import Bugsnag from '@bugsnag/js';
import { useTranslation } from 'next-i18next';
import 'moment/locale/nl';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/ja';
import 'moment/locale/ko';
import 'moment/locale/pt';
import 'moment/locale/zh-cn';

import { useToggle } from '@react-hookz/web';
import type { AxiosInstance, AxiosError } from 'axios';
import type { TFunction } from 'i18next';

import type { ErrorDescription, UserDetail } from 'src/api-sdk';
import { ProfilesApi, TokenApi } from 'src/api-sdk';

import { useAPI } from './use-api';
import { QUERIES } from './globals';

//
// AUTH CONTEXT INTERFACE
//
interface AuthContextInterface {
  user?: UserDetail;
  setRefreshToken: React.Dispatch<React.SetStateAction<string | undefined>>;
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
  refresh: (token: string) => Promise<{ access?: string }>;
  getUser: () => Promise<UserDetail>;
  isAuthenticationInitialized: boolean;
  refreshToken?: string;
  apiKey?: string;
  originalRequestUrl: string;
}

const authContext = React.createContext<AuthContextInterface>(
  {} as AuthContextInterface
);

interface ProvideAuthProps {
  children: React.ReactNode;
}

//
// PROVIDER & HOOK EXPORTS
//
export function ProvideAuth({ children }: ProvideAuthProps): JSX.Element {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = (): AuthContextInterface => useContext(authContext);

//
// API ERROR INTERCEPTOR (using async/await)
//
export function createApiErrorInterceptor({
  t,
  logout,
  refresh,
  refreshToken,
  api,
  router,
  setOriginalRequestUrl,
}: {
  t: TFunction;
  logout: () => void;
  refresh: (token: string) => Promise<{ access?: string }>;
  refreshToken?: string;
  api: AxiosInstance;
  router: ReturnType<typeof useRouter>;
  setOriginalRequestUrl: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}): (error: AxiosError) => Promise<any> {
  return async (error: AxiosError): Promise<any> => {
    // If there's no response, just pass the error along.
    if (!error.response) {
      throw error;
    }

    const url: string | undefined =
      error.request.url || error.request.responseURL;
    console.log('url', url, error.response);

    const data = error.response.data as ErrorDescription;

    // Incorrect credentials => log out.
    if (
      data &&
      (data.code === 'authentication_failed' ||
        data.code === 'account_inactive' ||
        data.code === 'user_not_found') &&
      refreshToken
    ) {
      console.log('Incorrect credentials, logging out.');
      logout();
      throw error;
    }

    // No Access Token provided => try to refresh.
    if (data && data.code === 'not_authenticated' && refreshToken) {
      console.log('Access Token not provided, refreshing');
      if (url && url.includes('/token/refresh/')) {
        console.info(
          'Token expired and refresh failed, redirecting to login (not authenticated).'
        );
        logout();
        throw error;
      }

      if (refreshToken) {
        await refresh(refreshToken);
        console.log('Refresh succeeded, continuing request');
        return api.request(error.config);
      } else {
        console.log('Refresh failed, redirecting to login');
        logout();
        throw error;
      }
    }

    // Access Token has expired => try to refresh.
    // @ts-ignore
    if (data && data.code === 'token_not_valid') {
      if (url && url.includes('/token/refresh/')) {
        console.info(
          'Token expired and refresh failed, redirecting to login (not valid).'
        );

        if (!isUnRestrictedRoute(router.pathname)) {
          console.log('Redirecting to', window.location.pathname);
          setOriginalRequestUrl(window.location.pathname);
          await router.push('/login');
        }
      } else if (refreshToken) {
        console.info('Token expired, trying to refresh using refresh token');
        const { access } = await refresh(refreshToken as string);
        console.log(
          'Token refreshed, retrying request to backend using new Access Token.'
        );
        // @ts-ignore
        error.config.headers['Authorization'] = `Bearer ${access}`;
        return api.request(error.config);
      } else {
        console.info('Token expired, but no refresh token found, logging out.');
        if (!isUnRestrictedRoute(router.pathname)) {
          console.log('Redirecting to', window.location.pathname);
          setOriginalRequestUrl(window.location.pathname);
        }
        await router.push('/login');
        return;
      }
    }

    // Handle specific input errors.
    if (data && data.description === 'Invalid input.') {
      data.title = t('Invalid Input.');
      data.description = t(
        'Something is incorrectly inputted, check your input and contact support if persistent.'
      );
    }

    // Notify Bugsnag and throw the error.
    Bugsnag.notify(error);
    throw error;
  };
}

//
// SESSION INTERCEPTOR HOOK
//
export function useSessionInterceptor({
  t,
  logout,
  refresh,
  refreshToken,
  api,
  setOriginalRequestUrl,
}: {
  t: TFunction;
  logout: () => void;
  refresh: (token: string) => Promise<{ access?: string }>;
  refreshToken?: string;
  api: AxiosInstance;
  setOriginalRequestUrl: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}): void {
  const router = useRouter();

  React.useEffect(() => {
    console.debug('Adding interceptor for keeping track of session.');
    const errorInterceptor = createApiErrorInterceptor({
      t,
      logout,
      refresh,
      refreshToken,
      api,
      router,
      setOriginalRequestUrl,
    });
    const interceptorId = api.interceptors.response.use(
      (response) => response,
      errorInterceptor
    );

    // Cleanup: eject the interceptor.
    return () => {
      api.interceptors.response.eject(interceptorId);
    };
  }, [t, logout, refresh, refreshToken, api, router, setOriginalRequestUrl]);
}

//
// PROVIDER HOOK: USEPROVIDEAUTH (using async/await)
//
function useProvideAuth(): AuthContextInterface {
  const router = useRouter();
  const { api, configuration, setAuthorization, authHeaderSet } = useAPI();
  const { t } = useTranslation('common');

  const [isAuthenticationInitialized, setIsAuthenticationInitialized] =
    useToggle(false);
  const [apiKey, setApiKey] = useState<string | undefined>();
  const [refreshToken, setRefreshToken] = useState<string | undefined>();
  const [originalRequestUrl, setOriginalRequestUrl] = useState<
    string | undefined
  >(undefined);

  /**
   * Get user details.
   */
  const getUser = React.useCallback(async (): Promise<UserDetail> => {
    const response = await new ProfilesApi(
      configuration,
      undefined,
      api
    ).profilesUserDetailsRetrieve();
    return response.data;
  }, [api, configuration]);

  const { data: user } = useQuery<UserDetail>([QUERIES.USER_DETAILS], getUser, {
    enabled: authHeaderSet,
  });

  /**
   * Login a user.
   */
  const login = React.useCallback(
    async (username: string, password: string): Promise<void> => {
      const response = await new TokenApi(
        configuration,
        undefined,
        api
      ).tokenCreate({
        tokenObtainPairRequest: { username, password },
      });
      setRefreshToken(response.data.refresh);
      setAuthorization(response.data.access);
      setCookies('authentication.refresh_token', response.data.refresh);
    },
    [api, configuration, setAuthorization]
  );

  /**
   * Refresh Access Token using the stored Refresh Token.
   */
  const refresh = React.useCallback(
    async (token: string): Promise<{ access?: string }> => {
      if (!token) {
        if (!isUnRestrictedRoute(router.pathname)) {
          console.log('Redirecting to', window.location.pathname);
          setOriginalRequestUrl(window.location.pathname);
          await router.push('/login');
        } else {
          throw new Error('Refresh token not supplied');
        }
      }
      try {
        const response = await new TokenApi(
          configuration,
          undefined,
          api
        ).tokenRefreshCreate({
          tokenRefreshRequest: { refresh: token },
        });
        if (response?.data?.refresh) {
          console.log('Setting new refresh token in cookie');
          setCookies('authentication.refresh_token', response.data.refresh);
          setRefreshToken(response.data.refresh);
        }
        setAuthorization(response.data.access, 'Bearer');
        return { access: response.data.access };
      } catch (error) {
        setRefreshToken(undefined);
        setCookies('authentication.refresh_token', null);
        console.log('Refresh failed, so redirecting to login');
        console.log(error);
        if (!isUnRestrictedRoute(router.pathname)) {
          console.log('Redirecting to', window.location.pathname);
          setOriginalRequestUrl(window.location.pathname);
          await router.push('/login');
        }
        throw error;
      }
    },
    [api, configuration, router, setAuthorization]
  );

  /**
   * Logout the user and clear the current session.
   */
  const logout = React.useCallback((): void => {
    console.log('Logging out');
    setRefreshToken(undefined);
    removeCookies('authentication.refresh_token');
    removeCookies('authentication.api_key');
    setApiKey(undefined);
    setAuthorization(null);
    setIsAuthenticationInitialized(false);
  }, [setAuthorization, setIsAuthenticationInitialized]);

  React.useEffect(() => {
    // Run the initialization code only once the router is ready.
    if (isAuthenticationInitialized || authHeaderSet || !router.isReady) {
      return;
    }

    (async () => {
      if (router.query.token) {
        console.log(`Received refresh token from URL`);
        setCookies(
          'authentication.refresh_token',
          router.query.token as string
        );
        setRefreshToken(router.query.token as string);
        await refresh(router.query.token as string);
      } else if (router.query.api_key) {
        console.log(`Received api key from URL`);
        const apiKeyFromQuery = router.query.api_key as string;
        setApiKey(apiKeyFromQuery);
        setCookies('authentication.api_key', apiKeyFromQuery);
        setAuthorization(apiKeyFromQuery, 'Token');
      } else if (getCookie('authentication.refresh_token')) {
        console.log(`Found refresh token in cookie`);
        await refresh(getCookie('authentication.refresh_token') as string);
        setRefreshToken(getCookie('authentication.refresh_token') as string);
      } else if (getCookie('authentication.api_key')) {
        console.log(`Found api key in cookie`);
        const apiKeyFromCookie = getCookie('authentication.api_key') as string;
        setApiKey(apiKeyFromCookie);
        setAuthorization(apiKeyFromCookie, 'Token');
      } else {
        // Redirect to /login if not on an unrestricted or registration route.
        if (
          !isUnRestrictedRoute(window.location.pathname) &&
          !isRegistrationRoute(window.location.pathname)
        ) {
          console.log('Setting original request URL', window.location.pathname);
          setOriginalRequestUrl(window.location.pathname);
          setAuthorization(null);

          console.log('Redirecting to login due to no credentials.');
          await router.push('/login');
        } else {
          console.log('No credentials found, stuck.');
        }
      }
      setIsAuthenticationInitialized(true);
    })();
  }, [
    refresh,
    router,
    setAuthorization,
    setOriginalRequestUrl,
    setIsAuthenticationInitialized,
    isAuthenticationInitialized,
    authHeaderSet,
  ]);

  useSessionInterceptor({
    t,
    logout,
    refresh,
    refreshToken,
    api,
    setOriginalRequestUrl,
  });

  return {
    user,
    setRefreshToken,
    login,
    logout,
    isAuthenticationInitialized,
    refresh,
    getUser,
    refreshToken,
    apiKey,
    originalRequestUrl: originalRequestUrl || '/',
  };
}

//
// ROUTE HELPERS
//
export const isRegistrationRoute = (pathname: string): boolean => {
  return (
    pathname.includes('/register') ||
    pathname.includes('/_error') ||
    pathname.includes('/register/verification/phone') ||
    pathname.includes('/register/verification/email') ||
    pathname.includes('/register/create-profile')
  );
};

export const isUnauthenticatedRoute = (pathname: string): boolean => {
  return (
    pathname.includes('/login') ||
    pathname.includes('/register') ||
    pathname.includes('/account/password-reset') ||
    pathname.includes('/invitations')
  );
};

export const isUnRestrictedRoute = (pathname: string): boolean => {
  return (
    pathname.includes('/_error') ||
    isUnauthenticatedRoute(pathname) ||
    (pathname.startsWith('/articles/') && pathname !== '/articles/') ||
    pathname.includes('/logout') ||
    pathname.includes('/alerts/feedback')
  );
};
