import { useInfiniteQuery } from '@tanstack/react-query';

import type { PaginatedPortfolioProfileUserGroupList } from 'src/api-sdk';
import type { InfiniteQueryHookOptions } from 'src/types/global';
import { useAPI } from 'src/util/use-api';
import { QUERIES } from 'src/util/globals';

type UsePortfolioGroupsParams = {
  searchQuery?: string;
  portfolioId?: string;
};

type Data = PaginatedPortfolioProfileUserGroupList | undefined;

export const usePortfolioGroups = (
  { portfolioId, searchQuery }: UsePortfolioGroupsParams,
  options: InfiniteQueryHookOptions<Data, Error, Data> = {}
) => {
  const { API } = useAPI();

  return useInfiniteQuery(
    [QUERIES.PORTFOLIO_GROUPS, portfolioId, searchQuery],
    async ({ pageParam }) => {
      return (
        await API?.portfoliosAPI.portfoliosProfileUserGroupsList({
          sorting: 'created_at',
          sortingOrder: 'descending',
          portfolioId: portfolioId!,
          query: searchQuery,
          nextKey: pageParam,
        })
      )?.data;
    },
    {
      ...options,
      getNextPageParam: (lastPage) => lastPage?.next_key ?? undefined,
      keepPreviousData: true,
    }
  );
};
