import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  type ReactNode,
} from 'react';
import type { AxiosInstance } from 'axios';
import axios from 'axios';
import { useRouter } from 'next/router';

// Import all API SDK endpoints.
import {
  AlertsApi,
  AnalyticsApi,
  CompaniesApi,
  ComplianceApi,
  DnbApi,
  FeedbackApi,
  NewsApi,
  PortfoliosApi,
  ProfilesApi,
  PublicationsApi,
  ReportsApi,
  ReviewsApi,
  TokenApi,
  BindersApi,
  BillingApi,
  Configuration,
} from 'src/api-sdk';

export type APIType = {
  analyticsAPI: AnalyticsApi;
  alertsAPI: AlertsApi;
  portfoliosAPI: PortfoliosApi;
  newsAPI: NewsApi;
  feedbackAPI: FeedbackApi;
  companiesAPI: CompaniesApi;
  dnbAPI: DnbApi;
  profilesAPI: ProfilesApi;
  publicationsAPI: PublicationsApi;
  complianceAPI: ComplianceApi;
  reportsAPI: ReportsApi;
  reviewsAPI: ReviewsApi;
  tokenAPI: TokenApi;
  bindersAPI: BindersApi;
  billingAPI: BillingApi;
};

interface APIContextInterface {
  api: AxiosInstance;
  baseApiUrl: string;
  authHeaderSet: boolean;
  setAuthorization: (token: string | null, scheme?: string) => void;
  configuration: Configuration;
}

// Create a context for the API instance.
const APIContext = createContext<APIContextInterface | null>(null);

/* ============================================================
   Provider: Creates and provides a configured API instance.
   ============================================================ */

export const ProvideAPI = ({ children }: { children: ReactNode }) => {
  const value = useProvideAPI();
  return <APIContext.Provider value={value}>{children}</APIContext.Provider>;
};

function useProvideAPI(): APIContextInterface {
  const router = useRouter();
  const baseApiUrl = process.env.NEXT_PUBLIC_API_URL as string;
  const [authHeaderSet, setAuthHeaderSet] = useState(false);
  const configuration = React.useMemo(() => {
    // console.log('Base path for API', baseApiUrl);
    return new Configuration({
      basePath: baseApiUrl,
    });
  }, [baseApiUrl]);

  // Create the Axios instance only once using useMemo.
  const api: AxiosInstance = useMemo(() => {
    const instance = axios.create({
      baseURL: baseApiUrl,
      headers: { 'Content-Type': 'application/json' },
    });
    // Example interceptor for requests.
    instance.interceptors.request.use(
      (config) => config,
      (error) => Promise.reject(error)
    );
    return instance;
  }, [baseApiUrl]);

  // Update the Accept-Language header whenever the locale changes.
  useEffect(() => {
    if (router.locale) {
      api.defaults.headers.common['Accept-Language'] = router.locale;
    }
  }, [api, router.locale]);

  /**
   * Set (or clear) the Authorization header.
   * @param token  The auth token (e.g. JWT string). If null, the header is removed.
   * @param scheme The auth scheme (default is "Bearer").
   */
  const setAuthorization = (token: string | null, scheme = 'Bearer') => {
    if (token) {
      api.defaults.headers.common['Authorization'] = `${scheme} ${token}`;
      setAuthHeaderSet(true);
    } else {
      delete api.defaults.headers.common['Authorization'];
      setAuthHeaderSet(false);
    }
  };

  return {
    api,
    baseApiUrl,
    configuration,
    authHeaderSet,
    setAuthorization,
  };
}

/* ============================================================
   Merged useAxios Hook: Provides both Axios + API instances.
   ============================================================ */

/**
 * useAxios returns the Axios instance, base URL, auth functions,
 * and (once the authorization header is set) the instantiated API endpoints.
 *
 * Wrap your app (or parts of it) with the `<ProvideAxios>` component.
 */
export function useAPI() {
  const context = useContext(APIContext);
  if (!context) {
    throw new Error('useAxios must be used within a ProvideAPI');
  }

  const { api, baseApiUrl, authHeaderSet, setAuthorization, configuration } =
    context;

  // Use useMemo to instantiate API endpoints once the auth header is set.
  const API: APIType = useMemo(() => {
    return {
      analyticsAPI: new AnalyticsApi(configuration, undefined, api),
      alertsAPI: new AlertsApi(configuration, undefined, api),
      portfoliosAPI: new PortfoliosApi(configuration, undefined, api),
      newsAPI: new NewsApi(configuration, undefined, api),
      feedbackAPI: new FeedbackApi(configuration, undefined, api),
      companiesAPI: new CompaniesApi(configuration, undefined, api),
      dnbAPI: new DnbApi(configuration, undefined, api),
      profilesAPI: new ProfilesApi(configuration, undefined, api),
      publicationsAPI: new PublicationsApi(configuration, undefined, api),
      complianceAPI: new ComplianceApi(configuration, undefined, api),
      reportsAPI: new ReportsApi(configuration, undefined, api),
      reviewsAPI: new ReviewsApi(configuration, undefined, api),
      tokenAPI: new TokenApi(configuration, undefined, api),
      bindersAPI: new BindersApi(configuration, undefined, api),
      billingAPI: new BillingApi(configuration, undefined, api),
    };
  }, [api, configuration]);

  return {
    api,
    baseApiUrl,
    authHeaderSet,
    setAuthorization,
    configuration,
    API,
  };
}
