import type { FC } from 'react';
import React from 'react';
import { memo } from 'react';
import { useCallback } from 'react';
import classnames from 'clsx';
import {
  Avatar,
  Button,
  Form,
  Input,
  Modal,
  notification,
  Typography,
} from 'antd';
import { useTranslation } from 'next-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import DeleteOutlineOutlined from '@mui/icons-material/DeleteOutlineOutlined';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useToggle } from '@react-hookz/web';

import { useBinderContext } from 'src/util/context/BinderContext';
import { useBinderItemNotes, usePostBinderNote } from 'src/hooks/useBinders';
import { usePaginatedData } from 'src/hooks/useNormalizePaginatedData';
import { QUERIES } from 'src/util/globals';
import { formatDateTime } from 'src/util/dateTime';
import type { BinderItemNote } from 'src/api-sdk';
import { useAuth } from 'src/util/use-auth';
import { useAPI } from 'src/util/use-api';
import { Loader } from 'src/components/Loader';
import BinderNoteTextEditor from 'src/pages/binders/[binder_id]/components/BinderNotesSummary/TextEditor';

import InfiniteScroll from '../InfiniteScroll';

type Inputs = {
  note: string;
};

const _EditableNote: FC<{
  note: BinderItemNote;
  currentUserId: string;
  binderId: string;
  binderItemId: string;
}> = ({
  note: {
    text,
    created_at,
    created_by: { first_name, last_name, external_id: note_owner_id },
    external_id,
  },
  currentUserId,
  binderId,
  binderItemId,
}) => {
  const { API } = useAPI();
  const client = useQueryClient();
  const { t } = useTranslation('notes');
  const isAbleToEdit = currentUserId === note_owner_id;
  const [loading, toggleLoading] = useToggle(false);
  const [editDeleteVisible, toggleEditDeleteVisible] = useToggle(false);
  const [editMode, toggleEditMode] = useToggle(false);
  const [editText, setEditText] = React.useState(text);
  const { mutateAsync: updateNote } = useMutation(
    async ({
      binderId,
      binderItemId,
      binderItemNoteId,
      text,
    }: {
      binderId: string;
      binderItemId: string;
      binderItemNoteId: string;
      text: string;
    }) => {
      return (
        await API?.bindersAPI.bindersItemsNotesUpdate({
          binderId,
          binderItemId,
          binderItemNoteId,
          binderItemNoteRequest: {
            text,
          },
        })
      )?.data;
    }
  );
  const { mutateAsync: deleteNote, isLoading: removeProgress } = useMutation(
    async () => {
      return (
        await API?.bindersAPI.bindersItemsNotesDestroy({
          binderId,
          binderItemId,
          binderItemNoteId: external_id,
        })
      )?.data;
    },
    {
      onSuccess: () => {
        notification.success({
          message: t('Note deleted successfully!'),
        });
        client.invalidateQueries([QUERIES.BINDER_ITEM_NOTES]);
        client.invalidateQueries([QUERIES.BINDER_DATA]);
        client.invalidateQueries([QUERIES.BINDER_NOTES, binderId]);
        client.invalidateQueries([QUERIES.BINDER_SUMMARY, binderId]);
      },
    }
  );
  const onChange = useCallback(
    (value) => {
      toggleLoading(true);
      updateNote({
        binderId,
        binderItemId,
        binderItemNoteId: external_id,
        text: value,
      }).then(() => {
        notification.success({
          message: t('Note modified successfully!'),
        });
        client.invalidateQueries([QUERIES.BINDER_ITEM_NOTES]);
        client.invalidateQueries([QUERIES.BINDER_DATA]);
        client.invalidateQueries([QUERIES.BINDER_NOTES, binderId]);
        client.invalidateQueries([QUERIES.BINDER_SUMMARY, binderId]);
        toggleLoading(false);
        toggleEditMode(false);
      });
    },
    [updateNote, external_id, binderId, binderItemId, client, t]
  );

  const onDelete = useCallback(() => {
    Modal.confirm({
      title: t('Are you sure you want to delete this note?'),
      onOk: () => {
        deleteNote();
      },
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      okText: t('Confirm'),
      cancelText: t('Cancel'),
      okButtonProps: {
        loading: removeProgress,
      },
    });
  }, [deleteNote, removeProgress, t]);

  // @ts-ignore
  return (
    <div
      className="flex flex-col"
      onMouseEnter={() => toggleEditDeleteVisible(true)}
      onMouseLeave={() => toggleEditDeleteVisible(false)}
    >
      <p className={'text-gray-600 rounded-md bg-gray-100'}>
        <div className={'!text-gray-700 p-3 flex justify-between items-center'}>
          <Loader loading={loading}>
            {editMode ? (
              <>
                <BinderNoteTextEditor value={editText} onChange={setEditText} />
                <Button
                  onClick={() => onChange(editText)}
                  size={'small'}
                  type={'primary'}
                  style={{
                    marginTop: 2,
                    marginBottom: 0,
                    width: 50,
                  }}
                  icon={<SaveOutlined />}
                />
              </>
            ) : (
              <span dangerouslySetInnerHTML={{ __html: text }} />
            )}
          </Loader>
          <div>
            {isAbleToEdit && editDeleteVisible && !editMode && (
              <Button
                onClick={toggleEditMode}
                size={'small'}
                style={{
                  marginTop: 2,
                  marginBottom: 0,
                }}
                icon={<EditOutlined />}
              />
            )}
            {isAbleToEdit && editDeleteVisible && !editMode && (
              <Button
                onClick={onDelete}
                size={'small'}
                style={{
                  marginTop: 2,
                  marginBottom: 0,
                }}
                icon={<DeleteOutlineOutlined color="error" />}
              />
            )}
          </div>
        </div>
      </p>
      <div className="flex flex-row text-gray-400 text-xs">
        <p>
          {first_name} {last_name} &bull;
        </p>
        <p className="ml-1">{formatDateTime(created_at)}</p>
      </div>
    </div>
  );
};

export const EditableNote = memo(_EditableNote);

export const Notes: FC = () => {
  const { user } = useAuth();
  const { t } = useTranslation('binders');
  const client = useQueryClient();
  const {
    binderItemNotesModalVisible,
    toggleBinderItemNotesModalVisible,
    setBinderId,
    setBinderItemId,
    binderId,
    binderItemId,
  } = useBinderContext();
  const [form] = Form.useForm<Inputs>();
  const {
    data: binderNotes,
    isLoading: binderNotesLoading,
    hasNextPage,
    fetchNextPage,
    isFetching,
  } = useBinderItemNotes({
    binderId: binderId!,
    binderItemId: binderItemId!,
    enabled: Boolean(binderItemNotesModalVisible && binderId && binderItemId),
  });
  const { mutateAsync: postNote, isLoading: creatingNoteProgress } =
    usePostBinderNote(
      { binderId: binderId!, binderItemId: binderItemId! },
      {
        onSuccess: () => {
          toggleBinderItemNotesModalVisible();
          notification.success({
            message: t('Note added successfully!'),
          });
          form.resetFields();
          client.invalidateQueries([QUERIES.BINDER_ITEM_NOTES]);
          client.invalidateQueries([QUERIES.BINDER_NOTES, binderId]);
          client.invalidateQueries([QUERIES.BINDER_DATA]);
          client.invalidateQueries([QUERIES.BINDER_SUMMARY, binderId]);
        },
      }
    );

  const { results: notes } = usePaginatedData(binderNotes);

  const handleSubmit = useCallback(async () => {
    const values = await form.validateFields();

    await postNote(values.note);
  }, [form, postNote]);

  return (
    <Modal
      title={t('Notes')}
      visible={binderItemNotesModalVisible}
      destroyOnClose
      afterClose={() => {
        setBinderId(null);
        setBinderItemId(null);
        form.resetFields();
        client.invalidateQueries([
          QUERIES.BINDER_ITEM_NOTES,
          binderId,
          binderItemId,
        ]);
      }}
      footer={null}
      onCancel={() => toggleBinderItemNotesModalVisible()}
    >
      <div className="flex flex-col">
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={handleSubmit}
        >
          <Form.Item
            name="note"
            label={t('Your note:')}
            rules={[{ required: true }]}
          >
            <BinderNoteTextEditor />
          </Form.Item>
          <div className="flex flex-row justify-end">
            <Button
              type="dashed"
              onClick={() => {
                form.resetFields();
              }}
            >
              {t('Clear')}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={creatingNoteProgress}
            >
              {t('Post note')}
            </Button>
          </div>
        </Form>

        {binderItemId && binderNotes ? (
          <>
            <div className="flex flex-row justify-between">
              <Typography.Text strong type="secondary">
                {t(`${binderNotes.pages[0]?.total_results} notes`)}
              </Typography.Text>
            </div>
            {binderNotes?.pages[0]?.total_results !== 0 && (
              <div className="flex flex-col mt-4 max-h-96">
                <InfiniteScroll
                  loadMore={fetchNextPage}
                  hasMore={hasNextPage}
                  isLoading={binderNotesLoading || isFetching}
                >
                  {notes.map((note) => (
                    <EditableNote
                      key={note.external_id}
                      note={note}
                      currentUserId={user!.external_id}
                      binderId={binderId!}
                      binderItemId={binderItemId!}
                    />
                  ))}
                </InfiniteScroll>
              </div>
            )}
          </>
        ) : null}
      </div>
    </Modal>
  );
};
