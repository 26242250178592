import { useInfiniteQuery } from '@tanstack/react-query';

import type { PaginatedBinderUserList } from 'src/api-sdk';
import type { InfiniteQueryHookOptions } from 'src/types/global';
import { useAPI } from 'src/util/use-api';
import { QUERIES } from 'src/util/globals';

type UseBinderUsersParams = {
  binderId: string;
  searchQuery?: string;
};

export const useBinderUsers = (
  { binderId, searchQuery }: UseBinderUsersParams,
  options: InfiniteQueryHookOptions<
    PaginatedBinderUserList | undefined,
    Error,
    PaginatedBinderUserList | undefined
  >
) => {
  const { API } = useAPI();

  return useInfiniteQuery(
    [QUERIES.BINDER_USERS, binderId, searchQuery],
    async ({ pageParam }) => {
      return (
        await API?.bindersAPI.bindersUsersList({
          sorting: 'created_at',
          sortingOrder: 'descending',
          binderId: binderId!,
          query: searchQuery,
          nextKey: pageParam,
        })
      )?.data;
    },
    {
      ...options,
      getNextPageParam: (lastPage) => lastPage?.next_key ?? undefined,
      keepPreviousData: true,
    }
  );
};
