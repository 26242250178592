import { useMutation } from '@tanstack/react-query';

import type { BinderUser, PermissionEnum } from 'src/api-sdk';
import type { MutationHookOptions } from 'src/types/global';
import { useAPI } from 'src/util/use-api';

type AddUserToPortfolioParams = {
  portfolioId: string;
  userId: string;
  permission: PermissionEnum;
};

export const useAddUserToPortfolio = (
  options: MutationHookOptions<
    BinderUser | undefined,
    Error,
    AddUserToPortfolioParams
  > = {}
) => {
  const { API } = useAPI();

  return useMutation(
    async ({ portfolioId, userId, permission }: AddUserToPortfolioParams) => {
      return (
        await API?.portfoliosAPI.portfoliosUsersCreate({
          portfolioId,
          portfolioUserRequest: {
            user: {
              external_id: userId,
            },
            permission,
          },
        })
      )?.data;
    },
    {
      ...options,
    }
  );
};
