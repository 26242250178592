import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { useRouter } from 'next/router';
import moment from 'moment';
import Bugsnag from '@bugsnag/js';
import { useTranslation } from 'next-i18next';
import Intercom from '@intercom/messenger-js-sdk';
import { hotjar } from 'react-hotjar';

import { useAPI } from 'src/util/use-api';

import { useWebsocket } from '../util/use-websocket';
import Topbar from './Topbar';
import SideBar from './Sidebar';
import FullScreenLoader from '../components/Loader';
import { isUnRestrictedRoute, useAuth } from '../util/use-auth';

interface AppLayoutProps {
  children: React.ReactNode;
}

const AppLayout = ({ children }: AppLayoutProps) => {
  const router = useRouter();
  const { i18n } = useTranslation();
  const { user, isAuthenticationInitialized } = useAuth();
  const { API, authHeaderSet } = useAPI();
  const { sendToWebsocket } = useWebsocket();
  const [lastPageVisited, setLastPageVisited] = React.useState<string | null>(
    null
  );
  /**
   * Automatically redirect the user to the correct locale if not already using an locale, so for example when
   * the user is visiting without specifying a locale it will use the user specific locale.
   */
  useEffect(() => {
    if (user && process.env.NEXT_PUBLIC_BUGSNAG_API_KEY) {
      Bugsnag.setUser(
        user.external_id,
        user.username,
        `${user.first_name} ${user.last_name}`
      );
    }
  }, [router, router.defaultLocale, router.locale, user, i18n.options]);

  useEffect(() => {
    if (user && process.env.NEXT_PUBLIC_INTERCOM_APP_ID) {
      Intercom({
        app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
        region: 'eu',
        name: `${user.first_name} ${user.last_name}`,
        email: user.username,
        user_hash: user.intercom_user_hash,
        company: {
          id: user.active_profile?.external_id,
          name: user.active_profile?.name,
        },
      });
    }

    if (
      process.env.NEXT_PUBLIC_HOTJAR_ID &&
      process.env.NEXT_PUBLIC_HOTJAR_SV &&
      user &&
      !user.is_staff
    ) {
      if (!hotjar.initialized()) {
        hotjar.initialize(
          parseInt(process.env.NEXT_PUBLIC_HOTJAR_ID),
          parseInt(process.env.NEXT_PUBLIC_HOTJAR_SV)
        );
        hotjar.identify(user.external_id, {
          username: user.username,
          email: user.email,
          first_name: user.first_name,
          last_name: user.last_name,
          language: user.language,
          active_profile: user.active_profile.name,
          is_complete_translation_enabled: user.is_complete_translation_enabled,
          is_compliance_enabled: user.is_compliance_enabled,
        });
      }
    }
  }, [user]);

  useEffect(() => {
    if (router.pathname === '/articles/[article_id]') {
      return;
    }
    if (user?.external_id && lastPageVisited !== window.location.href) {
      // Remove token && api_key from the query
      delete router.query.token;
      delete router.query.api_key;

      API?.profilesAPI.profilesAnalyticsPageVisitsCreate({
        pageVisitRequest: {
          current_page_name: router.pathname,
          parameters: router.query,
        },
      });

      if (Object.keys(router.query).length > 0) {
        console.log('Sending to websocket', router.query);
        sendToWebsocket({
          ...router.query,
        });
      }

      setLastPageVisited(window.location.href);
    }
  }, [
    router.query,
    router.pathname,
    API?.profilesAPI,
    user?.external_id,
    lastPageVisited,
    sendToWebsocket,
  ]);

  if (isUnRestrictedRoute(router.pathname)) {
    return (
      <Layout
        className={`gx-app-layout full-scroll horizontal-layout full-layout`}
      >
        <Layout>
          <Layout.Content className={`gx-layout-content gx-container-wrap`}>
            <div className="gx-main-content-wrapper">{children}</div>
            {/* <BG className="-mb-1 z-10" /> */}
            <Layout.Footer>
              <div className="gx-layout-footer-content">
                ©{moment().year()} Business Radar
              </div>
            </Layout.Footer>
          </Layout.Content>
        </Layout>
      </Layout>
    );
  }

  if (!isAuthenticationInitialized || !user || !authHeaderSet) {
    return <FullScreenLoader isMain={true} />;
  }

  return (
    <Layout
      className={`gx-app-layout full-scroll horizontal-layout full-layout`}
    >
      <SideBar />
      <Layout>
        <Topbar />
        <Layout.Content className={`gx-layout-content gx-container-wrap`}>
          <div className="gx-main-content-wrapper full-layout">{children}</div>
          <Layout.Footer style={{ marginTop: '15px' }}>
            <div className="gx-layout-footer-content">
              ©{moment().year()} Business Radar
            </div>
          </Layout.Footer>
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

// @ts-ignore
export default AppLayout;
