import { useInfiniteQuery } from '@tanstack/react-query';

import type { PaginatedPortfolioUserList } from 'src/api-sdk';
import type { InfiniteQueryHookOptions } from 'src/types/global';
import { useAPI } from 'src/util/use-api';
import { QUERIES } from 'src/util/globals';

type UsePortfolioUsersParams = {
  portfolioId: string;
  searchQuery?: string;
};

type Data = PaginatedPortfolioUserList | undefined;

export const usePortfolioUsers = (
  { portfolioId, searchQuery }: UsePortfolioUsersParams,
  options: InfiniteQueryHookOptions<Data, Error, Data>
) => {
  const { API } = useAPI();

  return useInfiniteQuery(
    [QUERIES.PORTFOLIO_USERS, portfolioId, searchQuery],
    async ({ pageParam }) => {
      return (
        await API?.portfoliosAPI.portfoliosUsersList({
          portfolioId: portfolioId,
          query: searchQuery,
          nextKey: pageParam,
        })
      )?.data;
    },
    {
      ...options,
      getNextPageParam: (lastPage) => lastPage?.next_key ?? undefined,
      keepPreviousData: true,
    }
  );
};
